import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Dashboard } from './../layouts'
import {
    TextField,
    Grid,
    Typography,
    Button,
    FormLabel
} from '@material-ui/core'
import {
    CustomSnackBar,
    BaseEditor
} from './../components'
import shortid from 'shortid'
import axios from './../plugins/axios'

const styles = theme => ({
    marginBottom: {
        margin: '0 0 20px 0'
    }
})

class SettingsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Тохиргоо',
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            introduction: '',
            goal: '',
            about: '',
            email: '',
            number: '',
            address: '',
            facebook: '',
            instagram: '',
            introductionTranslation: '',
            goalTranslation: '',
            aboutTranslation: '',
            addressTranslation: '',
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }


    fetchData = async () => {
        try {

            this.setState({
                key: shortid.generate()
            })

            const { data, translations } = await axios.get(`/options`, { progress: true })

            data.map(item =>
                this.setState({ [item.name]: item.value })
            )

            translations.map(item =>
                this.setState({ [`${[item.name]}Translation`]: item.value })
            )

        } catch (e) {
            //
        }
    }

    editorChange = name => e => {
        this.setState({
            [name]: e.target.getContent()
        })
    }

    update = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            introduction,
            goal,
            about,
            email,
            number,
            address,
            facebook,
            instagram,
            introductionTranslation,
            goalTranslation,
            aboutTranslation,
            addressTranslation
        } = this.state

        try {
            await axios.post('/options', {
                data: [
                    {
                        name: 'introduction',
                        value: introduction
                    },
                    {
                        name: 'goal',
                        value: goal
                    },
                    {
                        name: 'about',
                        value: about
                    },
                    {
                        name: 'email',
                        value: email
                    },
                    {
                        name: 'number',
                        value: number
                    },
                    {
                        name: 'address',
                        value: address
                    },
                    {
                        name: 'facebook',
                        value: facebook
                    },
                    {
                        name: 'instagram',
                        value: instagram
                    }
                ],
                translations: {
                    introduction: introductionTranslation,
                    goal: goalTranslation,
                    about: aboutTranslation,
                    address: addressTranslation,
                    email: email,
                    number: number,
                    facebook: facebook,
                    instagram: instagram
                }
            })

            this.setState({
                isSnackOpen: true,
                disabled: false
            })
        } catch (err) {
            this.setState({
                disabled: false
            })
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    render() {
        const {
            title,
            key,
            isSnackOpen,
            disabled,
            introduction,
            goal,
            about,
            email,
            number,
            address,
            facebook,
            instagram,
            introductionTranslation,
            goalTranslation,
            aboutTranslation,
            addressTranslation
        } = this.state

        const { marginBottom } = this.props.classes

        return (
            <Dashboard title={title}>
                <form
                    key={key}
                    onSubmit={this.update}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormLabel>Танилцуулга</FormLabel>
                            <div className={marginBottom}>
                                <BaseEditor
                                    value={introduction}
                                    onChange={this.editorChange('introduction')}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <FormLabel>Танилцуулга /Англи/</FormLabel>
                            <div className={marginBottom}>
                                <BaseEditor
                                    value={introductionTranslation}
                                    onChange={this.editorChange('introductionTranslation')}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <FormLabel>Зорилго</FormLabel>
                            <div className={marginBottom}>
                                <BaseEditor
                                    value={goal}
                                    onChange={this.editorChange('goal')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel>Зорилго /Англи/</FormLabel>
                            <div className={marginBottom}>
                                <BaseEditor
                                    value={goalTranslation}
                                    onChange={this.editorChange('goalTranslation')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel>Тухай</FormLabel>
                            <div className={marginBottom}>
                                <BaseEditor
                                    value={about}
                                    onChange={this.editorChange('about')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel>Тухай /англи/</FormLabel>
                            <div className={marginBottom}>
                                <BaseEditor
                                    value={aboutTranslation}
                                    onChange={this.editorChange('aboutTranslation')}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                Холбоо барих
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="И-мэйл"
                                value={email}
                                onChange={this.handleChange('email')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Утасны дугаар"
                                value={number}
                                onChange={this.handleChange('number')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Хаяг"
                                value={address}
                                onChange={this.handleChange('address')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Хаяг /Англи/"
                                value={addressTranslation}
                                onChange={this.handleChange('addressTranslation')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                Цахим хаягууд
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Facebook"
                                value={facebook}
                                onChange={this.handleChange('facebook')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Instagram"
                                value={instagram}
                                onChange={this.handleChange('instagram')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={disabled}
                    >
                        шинэчлэх
                    </Button>
                    <CustomSnackBar
                        message="Амжилттай шинэчлэгдлээ"
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </Dashboard >
        )
    }
}

export default withStyles(styles)(SettingsForm)
