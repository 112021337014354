import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { GalleryForm } from '../forms'
import { Dashboard } from '../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class GalleryNew extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Галерей',
            key: shortid.generate(),
            gallery_categories: []
        }
    }

    componentDidMount = () => {
        this.fetchGalleryCategories()
    }

    fetchGalleryCategories = async () => {
        try {
            const { data } = await axios.get('/gallery-categories', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                gallery_categories: data
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            key,
            title,
            gallery_categories
        } = this.state

        return (
            <Dashboard title={title}>
                <GalleryForm
                    key={key}
                    gallery_categories={gallery_categories}
                    path="gallery"
                    method="post"
                    message="Амжилттай нэмэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(GalleryNew)
