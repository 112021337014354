import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Box,
    Button,
    FormControl,
    Select,
    OutlinedInput,
    MenuItem,
    InputLabel,
    Grid,
    CircularProgress,
} from '@material-ui/core'
import DropZoneMultiple from './../components/DropZoneMultiple'
import {
    BaseDatePicker,
    CustomSnackBar,
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginBottom: {
        margin: '0 0 20px 0'
    }
})
class NewsForm extends Component {

    static defaultProps = {
        title: '',
        images: [],
        date: new Date(),
        body: '',
        news_categories: [],
        news_category_id: [],
    }

    constructor(props) {
        super(props)

        const {
            title,
            images,
            date,
            body,
            news_categories,
            news_category_id,
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            title,
            images,
            date,
            body,
            news_categories,
            news_category_id,
        }
    }


    componentDidMount = () => {
        this.fetchData()
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            title: '',
            images: [],
            date: new Date(),
            body: '',
            news_category_id: [],
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            title,
            images,
            date,
            body,
            news_category_id,
        } = this.state

        try {
            await axios[method](path, {
                title,
                images,
                date,
                body,
                news_category_id,
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onImagesChange = (path, bool) => {
        let copyImages = []
        if (bool === undefined) {
            this.setState(prevState => ({
                images: [...prevState.images, path]
            }))
        } else {
            copyImages = this.state.images.filter(val =>
                val !== path
            )
            this.setState({
                images: copyImages
            })
        }
    }

    fetchData = async () => {
        try {
            const data = await axios.get('/news-categories')
            this.setState({
                news_categories: data.data
            })
        } catch (e) {
            //
        }
    }

    handleDateChange = name => value => {
        this.setState({ [name]: value })
    }

    selectChanged = e => {
        const { value } = e.target

        this.setState({ news_category_id: value })
    }

    render() {
        const {
            title,
            images,
            date,
            body,
            news_categories,
            news_category_id,
            isSnackOpen,
            errors,
            disabled
        } = this.state

        const { marginBottom } = this.props.classes

        const { message } = this.props
        return (
            <div>

                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <TextField
                                required
                                error={!!errors.title}
                                helperText={errors.title}
                                label="Гарчиг"
                                value={title}
                                onChange={this.handleChange('title')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Box my={3}>
                                <BaseDatePicker
                                    error={!!errors.date}
                                    helperText={errors.date}
                                    label="Огноо"
                                    value={date}
                                    format={"yyyy/MM/dd"}
                                    onChange={this.handleDateChange('date')}
                                    animateYearScrolling
                                    required
                                />
                            </Box>
                            <TextField
                                required
                                error={!!errors.body}
                                helperText={errors.body}
                                label="Тайлбар"
                                value={body}
                                onChange={this.handleChange('body')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                rows={5}
                            />
                            <div className={marginBottom}>
                                <DropZoneMultiple
                                    label="Зурагууд"
                                    uploadPath="image/upload"
                                    onChange={this.onImagesChange}
                                    files={images}
                                    error={!!errors.images}
                                    fullWidth
                                    required
                                />
                            </div>
                            <Box my={3}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    required
                                >
                                    <InputLabel>Ангилал</InputLabel>
                                    <Select
                                        error={!!errors.news_category_id}
                                        helperText={errors.news_category_id}
                                        labelId="news_category_id-simple"
                                        value={news_category_id}
                                        onChange={this.selectChanged}
                                        input={
                                            <OutlinedInput />
                                        }
                                    >
                                        {
                                            news_categories.map(val =>
                                                <MenuItem
                                                    key={val.id}
                                                    value={val.id}
                                                >
                                                    {val.name}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={disabled}
                    >
                        {
                            disabled ? <CircularProgress size={25} color="primary" /> : "Хадгалах"
                        }
                    </Button>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div >
        )
    }
}

export default withStyles(styles)(NewsForm)
