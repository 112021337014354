import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Dashboard } from './../layouts'
import {
    BaseTable,
    BaseTableActions,
    ActionUpdateButton,
} from './../components'
import { pathJoin } from './../utils/helpers'

const styles = theme => ({
    //
})

class Partners extends Component {
    constructor(props) {
        super(props)

        this.table = React.createRef()

        this.state = {
            title: 'Хамтрагчид',
            columns: [
                {
                    name: 'name',
                    title: 'Нэр'
                },
                {
                    name: 'created_at',
                    title: 'Үүсгэсэн'
                },
                {
                    name: 'updated',
                    title: 'Шинэчилсэн'
                },
                {
                    name: 'Засварлах',
                    title: '',
                    getCellValue: ({ id }) => <ActionUpdateButton path={pathJoin('partners', id)} />
                }
            ],
        }
    }

    onDelete = async () => {
        return await this.table.current.fetchData()
    }

    render() {
        const {
            title,
            columns,
            tableColumnExtensions
        } = this.state

        return (
            <Dashboard title={title}>
                <BaseTableActions
                    newPath="/partners/new"
                    deletePath="/partners/mass/destroy"
                    onDelete={this.onDelete}
                />
                <BaseTable
                    path="partners"
                    columns={columns}
                    tableColumnExtensions={tableColumnExtensions}
                    innerRef={this.table}
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(Partners)
