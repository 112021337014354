import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button
} from '@material-ui/core'
import {
    CustomSnackBar,
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginBottom: {
        margin: '0 0 20px 0'
    }
})
class GalleryCategoryTranslationForm extends Component {

    static defaultProps = {
        name: '',
    }

    constructor(props) {
        super(props)

        const {
            name,
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            name
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            path,
            method,
            id
        } = this.props

        const {
            name,
        } = this.state

        try {
            await axios[method](path, {
                galleryCategoryId: id,
                name,
                locale: 'en',
            })

            this.setState({
                isSnackOpen: true
            })

            this.setState({
                disabled: false
            })
        } catch (err) {

        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    editorChange = e => {
        this.setState({
            body: e.target.getContent()
        })
    }

    render() {
        const {
            name,
            disabled,
            isSnackOpen
        } = this.state

        const { message } = this.props
        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <TextField
                        label="Нэр /англи/ "
                        value={name}
                        onChange={this.handleChange('name')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={disabled}
                    >
                        Хадгалах
                    </Button>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(GalleryCategoryTranslationForm)
