import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { NewsForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class NewsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formTitle: 'Мэдээ шинэчлэх',
            key: shortid.generate(),
            title: '',
            images: [],
            body: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/news/${id}`, { progress: true })

            this.setState({
                key: shortid.generate(),
                title: data.title,
                images: data.images,
                body: data.body
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            formTitle,
            key,
            title,
            images,
            body
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={formTitle}>
                <NewsForm
                    key={key}
                    path={`news/${id}`}
                    method="put"
                    title={title}
                    images={images}
                    body={body}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(NewsUpdate)
