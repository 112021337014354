import React from 'react'
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom'
import {
    Register,
    Login,
    ForgotPassword,
    PasswordReset,
    NotFound,
    Home,
    Requests,
    UserNew,
    Users,
    UserUpdate,
    GalleryCategoryNew,
    GalleryCategories,
    GalleryCategoryTranslation,
    GalleryCategoryUpdate,
    GalleryNew,
    Gallery,
    GalleryUpdate,
    EventNew,
    Events,
    EventTranslation,
    EventUpdate,
    ProjectNew,
    Projects,
    ProjectTranslation,
    ProjectUpdate,
    NewsCategoryNew,
    NewsCategories,
    NewsCategoryTranslation,
    NewsCategoryUpdate,
    NewsNew,
    News,
    NewsTranslation,
    NewsUpdate,
    ParticipantNew,
    Participants,
    ParticipantTranslation,
    ParticipantUpdate,
    PartnerNew,
    Partners,
    PartnerUpdate,
} from './pages'

import {
    SettingsForm,
    RequestForm,
} from './forms'

export default () => (
    <Router>
        <Switch>
            <Route
                path="/signin"
                component={Login}
            />
            <Route
                path="/signup"
                component={Register}
            />
            <Route
                path="/forgot/password"
                component={ForgotPassword}
            />
            <Route
                path="/password/reset/:token"
                component={PasswordReset}
            />
            <Route
                path="/"
                component={Home}
                exact
            />
            <Route
                path="/users/sadas"
                component={UserNew}
            />
            <Route
                path="/users/:id"
                component={UserUpdate}
            />
            <Route
                path="/users"
                component={Users}
            />
            <Route
                path="/gallery-categories/new"
                component={GalleryCategoryNew}
            />
            <Route
                path="/gallery-categories/:id/translation"
                component={GalleryCategoryTranslation}
            />
            <Route
                path="/gallery-categories/:id"
                component={GalleryCategoryUpdate}
            />
            <Route
                path="/gallery-categories"
                component={GalleryCategories}
            />
            <Route
                path="/gallery/new"
                component={GalleryNew}
            />
            <Route
                path="/gallery/:id"
                component={GalleryUpdate}
            />
            <Route
                path="/gallery"
                component={Gallery}
            />
            <Route
                path="/events/new"
                component={EventNew}
            />
            <Route
                path="/events/:id/translation"
                component={EventTranslation}
            />
            <Route
                path="/events/:id"
                component={EventUpdate}
            />
            <Route
                path="/events"
                component={Events}
            />
            <Route
                path="/projects/new"
                component={ProjectNew}
            />
            <Route
                path="/projects/:id/translation"
                component={ProjectTranslation}
            />
            <Route
                path="/projects/:id"
                component={ProjectUpdate}
            />
            <Route
                path="/projects"
                component={Projects}
            />
            <Route
                path="/news-categories/new"
                component={NewsCategoryNew}
            />
            <Route
                path="/news-categories/:id/translation"
                component={NewsCategoryTranslation}
            />
            <Route
                path="/news-categories/:id"
                component={NewsCategoryUpdate}
            />
            <Route
                path="/news-categories"
                component={NewsCategories}
            />
            <Route
                path="/news/new"
                component={NewsNew}
            />
            <Route
                path="/news/:id/translation"
                component={NewsTranslation}
            />
            <Route
                path="/news/:id"
                component={NewsUpdate}
            />
            <Route
                path="/news"
                component={News}
            />
            <Route
                path="/participants/new"
                component={ParticipantNew}
            />
            <Route
                path="/participants/:id/translation"
                component={ParticipantTranslation}
            />
            <Route
                path="/participants/:id"
                component={ParticipantUpdate}
            />
            <Route
                path="/participants"
                component={Participants}
            />
            <Route
                path="/partners/new"
                component={PartnerNew}
            />
            <Route
                path="/partners/:id"
                component={PartnerUpdate}
            />
            <Route
                path="/partners"
                component={Partners}
            />
            <Route
                path="/requests/:id"
                component={RequestForm}
            />
            <Route
                path="/requests"
                component={Requests}
            />
            <Route
                path="/settings"
                component={SettingsForm}
            />
            <Route component={NotFound} />
        </Switch>
    </Router>
)
