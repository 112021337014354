import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { GalleryForm } from '../forms'
import { Dashboard } from '../layouts'
import axios from '../plugins/axios'

const styles = theme => ({
    //
})

class GalleryUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Галерей',
            key: shortid.generate(),
            name: '',
            file: '',
            gallery_categories: [],
            gallery_category_id: []
        }
    }

    componentDidMount = () => {
        this.fetchData()
        this.fetchGalleryTypes()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/gallery/${id}`)

            this.setState({
                key: shortid.generate(),
                name: data.name,
                file: data.file,
                gallery_category_id: data.gallery_category_id
            })
        } catch (e) {
            //
        }
    }

    fetchGalleryTypes = async () => {
        try {
            const { data } = await axios.get('/gallery-categories', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                gallery_categories: data
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            file,
            gallery_categories,
            gallery_category_id
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <GalleryForm
                    key={key}
                    path={`gallery/${id}`}
                    method="put"
                    name={name}
                    file={file}
                    gallery_categories={gallery_categories}
                    gallery_category_id={gallery_category_id}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(GalleryUpdate)
