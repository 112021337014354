import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { EventTranslationForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'
import shortid from 'shortid'

const styles = theme => ({
    //
})

class EventTranslation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formTitle: 'Үйл ажиллагааны орчуулга',
            key: shortid.generate(),
            title: '',
            body: '',
            address: '',
            id: ''
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/events-translation/${id}`, { progress: true })
            this.setState({
                id: id,
                key: shortid.generate(),
                title: data.title,
                body: data.body,
                address: data.address
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            body,
            address,
            key
        } = this.state

        const { match } = this.props
        const { id } = match.params

        return (
            <Dashboard title={title}>
                <EventTranslationForm
                    key={key}
                    path="events-translation/"
                    id={id}
                    title={title}
                    body={body}
                    address={address}
                    method="post"
                    message="Амжилттай хадгалагдлаа"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(EventTranslation)
