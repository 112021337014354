import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Box,
    Button
} from '@material-ui/core'
import DropZone from './../components/DropZone'
import {
    CustomSnackBar,
    BaseEditor
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginBottom: {
        margin: '0 0 20px 0'
    }
})
class ProjectForm extends Component {

    static defaultProps = {
        title: '',
        image: '',
        topic: '',
        team: '',
        mentor: '',
        body: '',
        download: ''
    }

    constructor(props) {
        super(props)

        const {
            title,
            image,
            topic,
            team,
            mentor,
            body,
            download
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            title,
            image,
            topic,
            team,
            mentor,
            body,
            download
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            title: '',
            image: '',
            topic: '',
            team: '',
            mentor: '',
            body: '',
            download: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            title,
            image,
            body,
            topic,
            team,
            mentor,
            download
        } = this.state

        try {
            await axios[method](path, {
                title,
                image,
                body,
                topic,
                team,
                mentor,
                download
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    editorChange = e => {
        this.setState({
            body: e.target.getContent()
        })
    }

    onImageChange = name => (path = '') => {
        this.setState({
            [name]: path
        })
    }

    render() {
        const {
            title,
            image,
            topic,
            team,
            mentor,
            body,
            download,
            isSnackOpen,
            errors,
            disabled
        } = this.state

        const { marginBottom } = this.props.classes

        const { message } = this.props
        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <TextField
                        label="Гарчиг"
                        error={!!errors.title}
                        helperText={errors.title}
                        value={title}
                        onChange={this.handleChange('title')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Box my={3}>
                        <DropZone
                            label="Зураг"
                            uploadPath="image/upload"
                            onChange={this.onImageChange('image')}
                            file={image}
                            error={!!errors.image}
                        />
                    </Box>
                    <div className={marginBottom}>
                        <BaseEditor
                            value={body}
                            onChange={this.editorChange}
                        />
                    </div>
                    <TextField
                        label="Сэдэв"
                        error={!!errors.topic}
                        helperText={errors.topic}
                        value={topic}
                        onChange={this.handleChange('topic')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Багийн нэр"
                        error={!!errors.team}
                        helperText={errors.team}
                        value={team}
                        onChange={this.handleChange('team')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Зөвлөх"
                        error={!!errors.mentor}
                        helperText={errors.mentor}
                        value={mentor}
                        onChange={this.handleChange('mentor')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Татах холбоос"
                        error={!!errors.download}
                        helperText={errors.download}
                        value={download}
                        onChange={this.handleChange('download')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={disabled}
                    >
                        Хадгалах
                    </Button>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(ProjectForm)
