import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import shortid from 'shortid'
import {
    TextField
} from '@material-ui/core'
import { Dashboard } from '../layouts'
import axios from '../plugins/axios'

const styles = theme => ({
    //
})

class RequestForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Хүсэлт',
            key: shortid.generate(),
            fetching: false,
            disabled: false,
            name: '',
            email: '',
            subject: '',
            body: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/requests/${id}`, { progress: true })

            this.setState({
                key: shortid.generate(),
                name: data.name,
                email: data.email,
                subject: data.subject,
                body: data.body
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            key,
            title,
            name,
            email,
            subject,
            body
        } = this.state

        return (
            <Dashboard key={key} title={title}>
                <div>
                    <form>
                        <TextField
                            label="Нэр"
                            value={name}
                            variant="outlined"
                            margin="normal"
                            disabled
                            fullWidth
                        />
                        <TextField
                            label="И-мэйл"
                            value={email}
                            variant="outlined"
                            margin="normal"
                            disabled
                            fullWidth
                        />
                        <TextField
                            label="Тухай"
                            value={subject}
                            variant="outlined"
                            margin="normal"
                            disabled
                            fullWidth
                        />
                        <TextField
                            label="Сэтгэгдэл"
                            value={body}
                            variant="outlined"
                            margin="normal"
                            multiline
                            rows={10}
                            disabled
                            fullWidth
                        />
                    </form>
                </div>
            </Dashboard>
        )
    }
}

export default withStyles(styles)(RequestForm)
