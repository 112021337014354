import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button
} from '@material-ui/core'
import {
    CustomSnackBar,
    BaseEditor
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginBottom: {
        margin: '0 0 20px 0'
    }
})
class EventTranslationForm extends Component {

    static defaultProps = {
        title: '',
        body: '',
        cost: '',
        contact: '',
        address: ''
    }

    constructor(props) {
        super(props)

        const {
            title,
            body,
            cost,
            contact,
            address
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            title,
            body,
            cost,
            contact,
            address
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            path,
            method
        } = this.props

        const {
            title,
            body,
            cost,
            contact,
            address
        } = this.state

        try {
            await axios[method](path, {
                eventId: this.props.id,
                body,
                title,
                cost,
                contact,
                address,
                locale: 'en',
            })

            this.setState({
                isSnackOpen: true
            })

            this.setState({
                disabled: false
            })
        } catch (err) {

        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    editorChange = e => {
        this.setState({
            body: e.target.getContent()
        })
    }

    render() {
        const {
            title,
            body,
            cost,
            contact,
            address,
            isSnackOpen,
            disabled
        } = this.state

        const { marginBottom } = this.props.classes

        const { message } = this.props
        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <TextField
                        label="Гарчиг /англи/ "
                        value={title}
                        onChange={this.handleChange('title')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <div className={marginBottom}>
                        <BaseEditor
                            value={body}
                            onChange={this.editorChange}
                        />
                    </div>
                    <TextField
                        label="Үнэ /англи/ "
                        value={cost}
                        onChange={this.handleChange('cost')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Холбоо барих /англи/ "
                        value={contact}
                        onChange={this.handleChange('contact')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Хаяг /англи/ "
                        value={address}
                        onChange={this.handleChange('address')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={disabled}
                    >
                        Хадгалах
                    </Button>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(EventTranslationForm)
