import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { NewsCategoryTranslationForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'
import shortid from 'shortid'

const styles = theme => ({
    //
})

class NewsCategoryTranslation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formname: 'Мэдээний ангилал орчуулга',
            key: shortid.generate(),
            name: '',
            id: ''
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/news-categories-translation/${id}`, { progress: true })
            this.setState({
                id: id,
                key: shortid.generate(),
                name: data.name,
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            name,
            key
        } = this.state

        const { match } = this.props
        const { id } = match.params

        return (
            <Dashboard name={name}>
                <NewsCategoryTranslationForm
                    key={key}
                    path="news-categories-translation/"
                    id={id}
                    name={name}
                    method="post"
                    message="Амжилттай хадгалагдлаа"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(NewsCategoryTranslation)
