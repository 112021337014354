import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { EventForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class EventUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formTitle: 'Үйл ажиллагаа шинэчлэх',
            key: shortid.generate(),
            title: '',
            image: '',
            body: '',
            date: '',
            cost: '',
            contact: '',
            location: '',
            address: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/events/${id}`, { progress: true })

            this.setState({
                key: shortid.generate(),
                title: data.title,
                image: data.image,
                body: data.body,
                date: data.date,
                cost: data.cost,
                contact: data.contact,
                location: data.location,
                address: data.address
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            formTitle,
            key,
            title,
            image,
            body,
            date,
            cost,
            contact,
            location,
            address
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={formTitle}>
                <EventForm
                    key={key}
                    path={`events/${id}`}
                    method="put"
                    title={title}
                    image={image}
                    body={body}
                    date={date}
                    cost={cost}
                    contact={contact}
                    location={location}
                    address={address}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(EventUpdate)
